import React from 'react'
import blackLogo from "../../assets/images/blackLogo.png"
import comLogo from "../../assets/images/comLogo.svg"
import flag from "../../assets/images/flag.png"
import log from "../../assets/images/log.png"
import { Link } from 'react-router-dom'

function Header() {
    return (
        <>
           <header>
           <div className="cusotmeHeader">
                  <Link to='/'><span>
                  <img src={comLogo} alt="" />
                  </span></Link> 
                  <a href='https://commbitz.com/'><span>
                    <img src={blackLogo} alt="" />
                  </span></a>
                </div>
           </header>
        </>
    )
}

export default Header