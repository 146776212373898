import { API_URL } from "../../../config";
import { axios } from "../../../lib/axios";


const uploadImage = async (values:any) => {
    const headers:any = {
        'Content-Type': 'multipart/form-data',
      };
    return axios.post(`${API_URL}/common/upload`, values, headers);
}

const addDocuments = async (values:any) : Promise<any> => {
    return axios.post(`${API_URL}/order/freeEsim`, values);
}

export {
    uploadImage,
    addDocuments
};
