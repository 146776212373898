import React, { useRef, useState } from "react";
import styles from "./style.module.css"
import { useFormik } from "formik";
import { emailSchema } from "./validation";
import { addOrder, getDocumentStatus } from './api'
import { toast } from 'react-toastify';
import { GetGeoLoactions } from "../../utils/GetGeoLocation";
import { COMMBITZ_URL } from "../../config";
import MainLoader from "../mainLoader";
import { sendOtp, verifyOtp } from "../basicdetail/api";
import VerifyOtpModal from "./Verifition.tsx";
import { partnerLogin } from "../../lib/commonApis";
import { validateEmail } from "../../lib/customValidations";
import Swal from 'sweetalert2';
import calndr from '../../assets/images/calndr.png';


interface EmailModalProp {
  showModal: string;
  planId: string;
  closeModal: any
}

const EmailModal: React.FC<EmailModalProp> = ({ showModal, planId, closeModal }) => {
  const [errorMsg, setErrorMsg] = useState("")
  const [loader, setLoader] = useState<boolean>(false);
  const [otpId, setOTPID] = useState('');
  const [verifOTPId, setVerifOTPId] = useState('');
  const [show, setShow] = useState("")
  const [timer, setTimer] = useState(0);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const resendOtpHandle = useRef<HTMLAnchorElement>(null);


  const formik = useFormik({
    initialValues: {
      email: "",
      travellingDate :""
    },
    validationSchema: emailSchema,

    onSubmit: async (values, { setFieldError }) => {
      setLoader(true)
      if (!isEmailVerified) {
        setLoader(false)
        return setFieldError('email', 'email is not verified!');
      }
      const payload = {
        email: values?.email,
        otpId: verifOTPId,
        registrationType: 1,
        travellingDate: values?.travellingDate
      }
      partnerLogin(payload).then((data: any) => {
        const token: string = data?.data?.accessToken;
        const  userPartnerInfoId : string = data?.data?.userPartnerInfoId;        
        createOrder(token, userPartnerInfoId)
      }).catch((error: any) => {
        console.log(error)
        setLoader(false)
      })
    }
  });

  const createOrder = (token: string, userPartnerInfoId : string) => {
    // setLoader(true)
    GetGeoLoactions()
      .then(async (data: any) => {
        const encryptedMessage = btoa(token);
        const encrypteduserPartnerInfoId = btoa(userPartnerInfoId);
        // {latitude: 37.98597720335289, longitude :-103.31725006494221} for usa 
         // {latitude: 30.741677669344845, longitude :76.756976798372} for IN 
        getDocumentStatus(data).then((res: any) => {
          setLoader(false)
          closeModal("")
          if (res?.data?.showDocuments) {
            // navigate(`/document/${bundle?._id}/?paymentGateway=${res?.data?.paymentGatewayType}`)
            // navigate(`/document/${bundle?._id}`)
            setLoader(false)
            window.location.href = `${COMMBITZ_URL}/document/${planId}?token=${encryptedMessage}&userPartnerInfo=${encrypteduserPartnerInfoId}`
          } else {
            let orderPayload: any = {
              bundleId: planId,
              userPartnerInfoId
            };

            addOrder(orderPayload, token).then(Response => {
              // navigate(`/checkout/${Response?.data?.orderId}/?paymentGateway=${res?.data?.paymentGatewayType}`);
              // navigate(`/checkout/${Response?.data?.orderId}`);
              window.location.href = `${COMMBITZ_URL}/checkout/${Response?.data?.orderId}?token=${encryptedMessage}`
            }).catch((error) => {
              console.log(error)
              setLoader(false)
            });
          };
        }).catch((error: any) => {
          console.log(error)
          setLoader(false)
        })
      })
      .catch((error: any) => {
        console.log("Error getting location:", error);
        setLoader(false)
        Swal.fire("Please enable your location service!");
      });
  };

  const handleSendOtp = () => {
    const email: any = formik.values.email;
    if (validateEmail(email)) {
      formik.setErrors({ ...formik.errors, email: "" });
    } else if (formik.errors.email !== undefined || email === "") {
      return false;
    }
    const otpPayload = {
      email,
      type: 3
    }
    setLoader(true)
    toast.promise(
      sendOtp(otpPayload),
      {
        pending: {
          render() {
            return 'Trying to send OTP';
          }
        },
        success: {
          render({ data }) {
            setOTPID(data.data.otpId);
            setShow("show");
            setTimer(Date.now() + 30000);
            setFieldForVerify("email")
            setLoader(false)
            return 'OTP sent successfully';
          }
        },
        error: {
          render({ data }: any) {
            setLoader(false)
            return data.data.message;
          }
        }
      });
  }

  const otpVeriFy = (payload: any) => {
    setLoader(true)
    toast.promise(
      verifyOtp(payload),
      {
        pending: {
          render() {
            return 'Trying to verify OTP';
          }
        },
        success: {
          render({ data }: any) {
            setVerifOTPId(data?.data?.otpId)
            setIsEmailVerified(true)
            setLoader(false)
            setShow("")
            return 'OTP verified!';
          }
        },
        error: {
          render({ data }: any) {
            setLoader(false)
            return data.data.message;
          }
        }
      });
  }

  const [fieldForVerify, setFieldForVerify] = useState('')

  const handleResendOTP = () => {

    if (resendOtpHandle.current) {
      resendOtpHandle.current.click()
    }
  }
  const ClosedModal = (newValue: any) => {
    setShow(newValue);
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className={`modal fade ${showModal ? showModal : ''}`}
      style={showModal ? { display: "block" } : { display: "none" }}
    >
      {loader && <MainLoader />}
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal("")} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className={styles.EsimModal}>
              <p>Kindly share your Email Id for delivering your eSIM {" "}
                <a className="btn btn-primary" ref={resendOtpHandle} onClick={!isEmailVerified ? () => handleSendOtp() : () => ""}> {isEmailVerified ? "Verified" : "verify"}</a>
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.formGroup}>
                  <input type="email" placeholder="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email} />
                  {formik.touched.email && formik.errors.email && (
                    <div className={styles.error}>{formik.errors.email}</div>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Travelling Date*</label>
                  <input
                    type="date"
                    name="travellingDate"
                    placeholder="Select Date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.travellingDate}
                    min={today}
                  />
                  {/* <span><img src={calndr} alt="Calendar Icon" /></span> */}
                  {formik.touched.travellingDate && formik.errors.travellingDate && (
                    <div className={styles.error}>{formik.errors.travellingDate}</div>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <input type="submit" value="Submit" />
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
      <VerifyOtpModal
        show={show}
        otpId={otpId}
        timer={timer}
        otpVeriFy={otpVeriFy}
        fieldForVerify={fieldForVerify}
        handleResendOTP={handleResendOTP}
        ClosedModal={ClosedModal}
      />
    </div>
  )
}

export default EmailModal