import styles from '../styles/style.module.css';
import blackLogo from "../../../assets/images/blackLogo.png"
import blkn from "../../../assets/images/blkn.png"
import comLogo from "../../../assets/images/comLogo.svg"
import dnr from "../../../assets/images/dnr.png"
import steptwo from "../../../assets/images/steptwo.png"
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCountries } from '../api';

function BasicFormStepTwo() {
const country = window.localStorage.getItem("countryId")
  const navigate = useNavigate()
  const [countriesList, setCountriesList] = useState([])
  const [countryId, setCountryId] = useState( country || "")
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {    
    const stepOne = window.localStorage.getItem("step_one");
    if (!stepOne || stepOne != "stepOne") navigate('/basic-details')
    getCountries().then((res) => {
      setCountriesList(res?.data?.countries)
    }).catch((error) => console.log(error))

  }, [])
  
  const handleCountrySubmit = (e: any) => {

    if (e.type == "change") {
      
      if (!e.target.value) {
        setErrorMsg("Please select country!")
      } else {
        setErrorMsg("")
      }
      
      setCountryId(e.target.value)
      
    } if (e.type == "click") {
      if (!countryId) {
        setErrorMsg("Please select country!")
        return false
      }
      window.localStorage.setItem("countryId", countryId)
      navigate('/basic-details/step-two')
    }

  }

  return (
    <div>
      <div className={styles.bassoDtailFoem}>
        <div className={styles.getStartBk}>
          <div className={styles.stepHeader}>
            <a href='https://commbitz.com/'><span>
              <img src={blkn} alt="" />
            </span></a>
            <Link to='/'><span>
              <img src={blackLogo} alt="" />
            </span></Link>
          </div>
          <div className={`${styles.stepMain} ${styles.stepTwo}`}>
            <img src={steptwo} alt="" />
            <h3>Where are you traveling <br /> to?</h3>
            <div className={styles.formFroup}>
              <label>Which country are you planning to travel to?*</label>
              <select onChange={handleCountrySubmit} value={countryId}>
                <option key={1} value="">Select Country</option>
                {countriesList && countriesList.map((list: any) => (
                  <option key={list?._id} value={list?._id}>{list?.name}</option>
                ))}
              </select>
                <span> <img src={dnr} alt="" /></span>
            </div>
            {errorMsg &&
                <span className={styles.error} >{errorMsg}</span>
              }
            <button onClick={handleCountrySubmit}>Get Started <i className="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicFormStepTwo
