import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import comLogo from "../../../assets/images/comLogo.svg";
import blackLogo from "../../../assets/images/blackLogo.png"
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styles from '../styles/style.module.css';
import Layout  from "../../Layout";
import {  addDocuments, uploadImage } from '../api';
import { UploadDocumentSchema } from '../validations';
import MainLoader from '../../../components/mainLoader';
import Resizer from 'react-image-file-resizer';
import useAuth from '../../../lib/useAuth';
import Congratulations from "../../modal/congratulation";

function Document() {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [disable, setDisable] = useState(false);
    const [deviceToken, setDeviceToken] = useState('');
    const [loader, setLoader] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [images, setImages] = useState({ passportFront: "", passportBack: '', visa: '' });
    const {logout} = useAuth()
    const formData = new FormData();
    useEffect(() => {
        const formSubmitted = window.localStorage.getItem('formSubmited')
        if(formSubmitted == "submited") logout()
        if(!window.localStorage.getItem("token")) navigate('/basic-details')
        
        window.scrollTo(0, 0);
    }, [])
const userPartnerInfoId = window.localStorage.getItem("userPartnerInfoId")
    const uploadFile = (formData: FormData, key: string) => {
        setLoader(true)
        toast.promise(
            uploadImage(formData),
            {
                pending: {
                    render() {
                        return 'Uploading File';
                    }
                },
                success: {
                    render({ data }) {
                        documentFormik.setFieldValue(key, data?.data?.fileName);
                        setLoader(false)
                        setImages({ ...images, [key]: data.data.url });
                        return 'File uploaded successfully';
                    }
                },
                error: {
                    render({ data }: any) {
                        setLoader(false)
                        return 'Failed to upload File';
                    }
                }
            });
    };
    const validTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    const handlePassportFrontChange = async (event: any) => {
        if (event.currentTarget.files) {
            const file = event.currentTarget.files[0];
            
            if (!validTypes.includes(file.type)) {
                documentFormik.setFieldError("passportFront", "Please upload a PNG, JPG, or JPEG file.");
                return;
            }

            Resizer.imageFileResizer(
                file,
                300, // max width
                300, // max height
                'JPEG', // format
                100, // quality
                0, // rotation
                (uri: any) => {
                    const resizedFile = new File([uri], file.name, { type: file.type });
                    const formData = new FormData();
                    formData.append('file', resizedFile);
                    setDisable(true);
                    uploadFile(formData, 'passportFront');
                },
                'blob' // output type
            );
            setDisable(true)
        }
    };

    const handlePassportBackChange = async (event: any) => {
        if (event.currentTarget.files) {

            const file = event.currentTarget.files[0];
            if (!validTypes.includes(file.type)) {
                documentFormik.setFieldError("passportBack", "Please upload a PNG, JPG, or JPEG file.");
                return;
            }

            Resizer.imageFileResizer(
                file,
                300, // max width
                300, // max height
                'JPEG', // format
                100, // quality
                0, // rotation
                (uri: any) => {
                    const resizedFile = new File([uri], file.name, { type: file.type });
                    const formData = new FormData();
                    formData.append('file', resizedFile);
                    setDisable(true);
                    uploadFile(formData, 'passportBack');
                },
                'blob' // output type
            );
            setDisable(true)
        }
    };

    const handleVisaChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {

            const file = event.currentTarget.files[0];
            if (!validTypes.includes(file.type)) {
                documentFormik.setFieldError("visa", "Please upload a PNG, JPG, or JPEG file.");
                return;
            }

            Resizer.imageFileResizer(
                file,
                300, // max width
                300, // max height
                'JPEG', // format
                100, // quality
                0, // rotation
                (uri: any) => {
                    const resizedFile = new File([uri], file.name, { type: file.type });
                    const formData = new FormData();
                    formData.append('file', resizedFile);
                    setDisable(true);
                    uploadFile(formData, 'visa');
                },
                'blob' // output type
            );
            setDisable(true)
        }
    };

    const documentFormik = useFormik({
        initialValues: {
            passportFront: '',
            passportBack: '',
            visa: ''
        },
        validationSchema: UploadDocumentSchema,
        onSubmit: async (values) => {
            setLoader(true)
            let orderPayload: any = {
                documents: {
                    passportFront: values.passportFront,
                    passportBack: values.passportBack,
                    visa: values.visa
                },
                userPartnerInfoId
            };

            toast.promise(
                addDocuments(orderPayload),
                {
                    pending: {
                        render() {
                            return 'Trying to create uploads documents';
                        }
                    },
                    success: {
                        render({ data }) {
                            window.localStorage.setItem("formSubmited", "submited")
                            setLoader(false)
                            setShowModal(true)
                            return 'Documents uploaded successfully';
                        }
                    },
                    error: {
                        render({ data }: any) {
                            setLoader(false)
                            return data?.data?.message || "An error occurred";
                        }
                    }
                });
        },
    });

    return (
        <>
            {loader && <MainLoader />}
            <div className={styles.uploadDoc}>
           
                <div className='container'>
                    <h3>Upload Documents</h3>
                    <div className={styles.outerUpoad}>
                        <form onSubmit={documentFormik.handleSubmit}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className={styles.innerUpload}>
                                        <h5>Passport</h5>
                                        <h6>Upload Passport Images </h6>
                                        <p>Upload a clear picture of your Passport</p>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className={styles.uploadFrame}>
                                                    {
                                                        images.passportFront.length ?
                                                            <img src={images.passportFront} alt="passport_front" />
                                                            :
                                                            <Fragment>
                                                                <input
                                                                    type='file'
                                                                    name="passportFront"
                                                                    onChange={handlePassportFrontChange}
                                                                    onBlur={documentFormik.handleBlur}
                                                                    readOnly={disable}
                                                                />
                                                                <span>
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </span>
                                                            </Fragment>
                                                    }

                                                </div>
                                                <span className={styles.tagName}>Front</span>
                                                {
                                                    documentFormik.touched.passportFront && documentFormik.errors.passportFront && (
                                                        <div className={styles.error}>{documentFormik.errors.passportFront}</div>
                                                    )
                                                }
                                            </div>
                                            <div className='col-md-6'>
                                                <div className={styles.uploadFrame}>
                                                    {
                                                        images.passportBack.length ?
                                                            <img src={images.passportBack} alt='passport_back' />
                                                            :
                                                            <Fragment>
                                                                <input
                                                                    type='file'
                                                                    readOnly={disable}
                                                                    name="passportBack"
                                                                    onChange={handlePassportBackChange}
                                                                    onBlur={documentFormik.handleBlur}
                                                                />
                                                                <span>
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </span>
                                                            </Fragment>
                                                    }

                                                </div>
                                                <span className={styles.tagName}>Back</span>
                                                {
                                                    documentFormik.touched.passportBack && documentFormik.errors.passportBack && (
                                                        <div className={styles.error}>{documentFormik.errors.passportBack}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={styles.innerUpload}>
                                        <h5>Visa/Ticket</h5>
                                        <h6>Upload VISA/TICKET Images  </h6>
                                        <p>Upload a clear picture of VISA/TICKET</p>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className={styles.uploadFrame}>
                                                    {
                                                        images.visa.length ?
                                                            <img src={images.visa} alt='visa' />
                                                            :
                                                            <Fragment>
                                                                <input
                                                                    type='file'
                                                                    readOnly={disable}
                                                                    name="visa"
                                                                    onChange={handleVisaChange}
                                                                    onBlur={documentFormik.handleBlur} />
                                                                <span>
                                                                    <i className="fas fa-plus-circle"></i>

                                                                </span>
                                                            </Fragment>
                                                    }

                                                    {/* <img src={blogone} alt=""/> */}
                                                </div>
                                                <span className={styles.tagName}>Front</span>
                                                {
                                                    documentFormik.touched.visa && documentFormik.errors.visa && (
                                                        <div className={styles.error}>{documentFormik.errors.visa}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button className={styles.submit} type="submit" >submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Congratulations show={showModal}/>
        </>
    )
}

export default Document