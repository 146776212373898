import { useEffect, useState } from "react";
// const banner = require("../../assets/images/banner.png")
import niyobnr from "../../assets/images/niyobnr.png";
import blackLogo from "../../assets/images/blackLogo.png"
import noFound from "../../assets/images/noFound.png"
import countryone from "../../assets/images/countryone.png"
import comLogo from "../../assets/images/comLogo.svg";
import mobilebnr from "../../assets/images/mobilebnr.png";
import data from "../../assets/images/data.svg";
import validity from "../../assets/images/validity.svg";
import flags from "../../assets/images/flags.png";
import speed from "../../assets/images/speed.svg";
import dis from "../../assets/images/dis.svg"
// import { useLocation, useNavigate } from 'react-router-dom';
import { getBundles, getCountries, getPopularCountries, getPopularCountriesBundls } from "./api";
import Pagination from "../pagination";
import Layout from "../Layout";
import { useLocation, useNavigate } from "react-router-dom";
import EmailModal from "../modal/EmailModal";
import PaymentSuccess from "../modal/PaymentSucccess";
import MainLoader from "../mainLoader";
import Slider from "react-slick";
import useAuth from "../../lib/useAuth";

interface Params {
  limit: number;
  page: number;
  search: string;
  countryId?: string; // Mark countryId as optional
  showAllPlans: boolean;
}

function Landing() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status: any = queryParams.get("status");
  const [countries, setCountries] = useState<any[]>([]);
  const [countryId, setCountryId] = useState<string>("");
  const [searchVal, setSearchVal] = useState<any>("");
  const [bundles, setBundles] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [showModal, setShowModal] = useState("");
  const [success, setSuccess] = useState("");
  const [planId, setPlanId] = useState<string>("");
  const [counts, setCounts] = useState<number>(0);
  const [countriesSuggestion, setCountriesSuggestion] = useState<any>([]);
  const [suggestionShow, setSuggestionShow] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState("");

  const handleSearchSubmit = (event: any) => {
    event.preventDefault();
    if (event.type == 'change') {
      setSearchVal(event.target.value)
      if (event.target.value) {
        getPopularCountries({ limit: 50, searchString: event.target.value })
          .then((res: any) => {
            if (res?.data?.length > 0) setSuggestionShow(true)
            setCountriesSuggestion(res.data);
          })
        setCurrentPage(1);
      } else {
        setCountriesSuggestion([]);
        setSuggestionShow(false)
      }
    } else if (event.type == 'submit') {
      setSearchParams(searchVal);
      setCountriesSuggestion([]);
      setSuggestionShow(false)
    }


  };

  const closeModal = (value: any) => {
    setShowModal(value);
    if (success) navigate(`/`, { replace: false });
    setSuccess(value);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };



  const pageRecords = 12;

  useEffect(() => {
    status ? setSuccess("show") : setSuccess("");
    const params: Params = {
      showAllPlans: true,
      limit: pageRecords,
      page: currentPage,
      search: searchParams || "",
    };

    if (searchParams) {
      params.search = searchParams;
      setShowPagination(true)
    } else {
      setShowPagination(false)
    }

    if (countryId) {
      params.countryId = countryId;
      setShowPagination(true)
    } else {
      setShowPagination(false)
    }

    getPopularCountriesBundls(params).then((res: any) => {
      setCounts(res.data.count);
      setBundles(res.data.bundles);
      setLoader(false)
      if (searchParams || countryId || currentPage != 1) {
        window.scrollTo(850, 850);
      } else {
        window.scrollTo(0, 0);
      }

    })
      .catch((error) => {
        setLoader(false)
        console.log(error);
      });

  }, [currentPage, searchParams, countryId, status]);

  useEffect(() => {
    getPopularCountries({ limit: 50 })
      .then((res: any) => {
        const getData = res.data.filter((country: any) => country.isPopular)
        setCountries(getData);
        setLoader(false)
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });

    window.scrollTo(0, 0);

  }, []);

  const popularCountry = (value: string) => {
    setCountryId(value)
    setCurrentPage(1)
    setLoader(true)
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 0,
    // centerMode: true,
    // variableWidth: true,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // or any width you consider to be "mobile"
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <Layout>
      {loader && <MainLoader />}
      <section className="banner-section">
        <div className="bnr-img desktop">
          <img className="desktopBnr" src={niyobnr} alt="" />
          {/* <img className="mobilepBnr" src={mobilebnr} alt="" /> */}
        </div>
        <div className="bnr-img mobile">
          <img className="mobilepBnr" src={mobilebnr} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <div className="row">

              <div className="col-md-12">
                <div className="niyoLogo">
                <span>
                    <img src={comLogo} alt="" />
                  </span>
                  <span>
                    <img src={blackLogo} alt="" />
                  </span>
                  
                </div>
                <div className="left-abner">

                  <h2>
                    Inexpensive Data Roaming with<br /> Our Travel eSIM
                  </h2>
                  <div className="Serch">
                    <form onSubmit={handleSearchSubmit}>
                      <input type=" text" placeholder="Search Country, Region here"
                        onChange={handleSearchSubmit}
                        value={searchVal}
                      />
                      <div className={`${suggestionShow ? 'showSuggestion' : 'hideSuggestion'} search-suggestion`}>
                        {countriesSuggestion && countriesSuggestion.length > 0 && countriesSuggestion.map((list: any, index: number) => (
                          <p className="select-itme" key={list?._id} onClick={() => {
                            setSearchVal(list?.name);
                            setSearchParams(list?.name)
                            setSuggestionShow(false)
                          }
                          }>{list?.name}</p>
                        ))}

                      </div>
                    </form>

                  </div>
                  <div className="ourCountry">
                    <h6>Popular Countries</h6>
                    <div className="countrySlide">
                      <Slider {...settings}>
                        {
                          countries && countries.map((list: any) => (
                            <a href="" onClick={(e) => {
                              if (countryId === list?._id) {
                                e.preventDefault();
                              } else {
                                e.preventDefault();
                                setSearchParams('');
                                setSearchVal('');
                                popularCountry(list?._id);
                              }
                            }
                            } >
                              <div className="cntFlags">
                                <img src={list?.flagImageUrl} alt="" />
                                <span className="bgCountry"> <img src={list?.historicImageUrl} alt="" /></span>
                                <span key={list?._id} >
                                  {list?.name}</span>
                              </div>
                            </a>
                          ))
                        }
                      </Slider>
                    </div>

                  </div>
                  <div className="discount">
                    <img src={dis} alt="" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="moble-country">
        <div className="container">
          <div className="ourCountry">
            <h6>Popular Countries</h6>
            <div className="countrySlide">

              {
                countries && countries.map((list: any) => (
             
                    <div className="cntFlags">
                           <a href="" onClick={(e) => {
                    if (countryId === list?._id) {
                      e.preventDefault();
                    } else {
                      e.preventDefault();
                      setSearchParams('');
                      setSearchVal('');
                      popularCountry(list?._id);
                    }
                  }
                  } >
                      <img src={list?.flagImageUrl} alt="" />
                      <span className="bgCountry"> <img src={list?.historicImageUrl} alt="" /></span>
                      <span key={list?._id} >
                        {list?.name}</span>
                        </a>
                    </div>
                 
                ))
              }

            </div>

          </div>
        </div>
      </section>
      {/* <section className="mob-section">
                <div className="container">
                    <div className="right-abner">
                        <h4>Find affordable plans for<br /> your travel eSIM</h4>
                        <form>
                            <div className="form-group">
                                <label>Country</label>
                                <input type="text" placeholder="Choose Counrty" />
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Get Plans" />
                            </div>
                        </form>
                    </div>
                </div>
            </section> */}
      <section className="counter-section">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-3">
              <div className="count-inner">
                <h5>10K +</h5>
                <span>Active Clients</span>
              </div>
            </div>*/}
            <div className="col-md-4">
              <div className="count-inner">
                <h5>364 +</h5>
                <span>Active Countries</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="count-inner">
                <h5>70 +</h5>
                <span>Available Plans</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="count-inner">
                <h5>63 K</h5>
                <span>Happy Clients</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="esims">
        <div className="container">
          <div className="esminHeader">
            <h3>Find the Best eSIM Plan for Your Destination!</h3>
            <button onClick={() => {
              setCurrentPage(1)
              setSearchParams('')
              setCountryId('')
              setSearchVal("")
              setShowPagination(false)
            }}><i className="fas fa-filter" ></i> Clear filter</button>
          </div>

          {/* <div className="form-group">
            <form>
              <input
                type="text"
                placeholder="Search Country, Regions, Plans"
                value={searchParams}
                onChange={handleSearchSubmit}
              />
              <div className={`${suggestionShow ? 'showSuggestion' : 'hideSuggestion'} search-suggestion`}>
                {countriesSuggestion && countriesSuggestion.length > 0 && countriesSuggestion.map((list:any, index:number)=>(
                    <p className="select-itme" key={list?._id} onClick={()=>
                      {
                        setSearchParams(list?.name); 
                        setSuggestionShow(false)
                      }
                    }>{list?.name}</p>
                ))}
                
              </div>
              <button>
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div> */}
          {/* <ul className="filter-am">
            <li
              className={countryId == "all" ? "active" : ""}
              onClick={() => setCountryId("all")}
            >
              <span>All</span>
            </li>
            {countries &&
              countries.map((country) => (
                <li
                  className={countryId == country?._id ? "active" : ""}
                  onClick={() => { 
                    setCountryId(country?._id) ; 
                    setLoader(true)
                    setCurrentPage(1)
                  }}
                >
                  <span>{country?.name}</span>
                </li>
              ))}
          </ul> */}
          <div className="row">
            {bundles.length > 0 ? (
              bundles.map((bundle, index) => (
                <div className="col-md-3" key={bundle?._id}>
                  <div className="esimDetails">
                    <div className="FirtPack">
                      <div className="PackHead">
                        <div className="PackHeadLeft">
                          <h3>{bundle?.name} <br /> <span>Country</span></h3>
                          {/* <h4>UKi Mobile</h4>
                                                    <p>Company</p> */}
                        </div>
                        <div className="PackHeadRight">
                          <span>
                            {bundle?.priceSymbol} {bundle?.price.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      {/* <h3>{bundle?.name}</h3> */}
                      <ul>
                        <li>
                          <span>
                            <img src={data} alt="" /> Data
                          </span>
                          <h5>
                            {bundle && (bundle?.dataAmount / 1000)}GB
                          </h5>
                        </li>
                        <li>
                          <span>
                            <img src={validity} alt="" /> Validity
                          </span>
                          <h5>{bundle?.duration} Days</h5>
                        </li>
                        <li>
                          <span>
                            <img src={speed} alt="" /> Speed
                          </span>
                          <h5>
                            {bundle?.speed && bundle.speed.length > 0
                              ? bundle.speed.join(", ")
                              : "-"}
                          </h5>
                        </li>
                      </ul>
                      <div className="viewAll">
                        <button
                          type="button"
                          className="buySimBtn"
                          onClick={() => {
                            setShowModal("show");
                            setPlanId(bundle?._id);
                          }}
                        >
                          BUY NOW
                        </button>
                        {bundle?.viewAllButton && <a href=""
                          onClick={(e) => {
                            e.preventDefault()
                            setSearchParams(bundle?.name)
                          }}
                        >View all</a>}
                      </div>

                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="NotFound">
                <img src={noFound} alt="" />
              </div>
            )}
          </div>
        </div>
        {showPagination && <Pagination
          count={counts} // You can replace this with the actual count received from the API
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          itemsPerPage={pageRecords}
        />}

        <EmailModal
          showModal={showModal}
          planId={planId}
          closeModal={closeModal}
        />
        <PaymentSuccess showModal={success} closeModal={closeModal} />
      </section>
    </Layout>
  );
}

export default Landing;
