import * as Yup from "yup";
const today = new Date();
today.setHours(0, 0, 0, 0);

// Helper function to parse dates correctly
const parseDateString = (value: any, originalValue: any): Date => {
  const parsedDate = Date.parse(originalValue);
  return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
};

// Define the email schema with the regex
export const emailSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Please enter an email"),
    travellingDate: Yup.date()
      .required("Travelling date is required")
      .typeError("Invalid date format")
      .transform(parseDateString)
      .min(today, "Travelling date must be today or later"),
  });
};
