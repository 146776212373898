import { API_URL } from '../../../config';
import { axios } from '../../../lib/axios';

const addBasicDetails = async (values: any): Promise<any> => {

    const headers = {
      'X-Origin-Domain' : "niyo.commbitz.com"
    };
  
    // Pass the headers as the third parameter
    return axios.post(`${API_URL}/auth/partnerLogin`, values, { headers });
  };

const getCountries = async (): Promise<any> => {
    return await axios.get(`${API_URL}/regions/countries?limit=300`);
}

const sendOtp = async (values: any): Promise<any> => {
  return axios.post(`${API_URL}/otp/send`, values);
}

const verifyOtp = async (values: any): Promise<any> => {
  return axios.post(`${API_URL}/otp/verify`, values);
}
const checkNiyoUserEmail = async (values: any): Promise<any> => {
  return axios.get(`${API_URL}/partner-websites/customer/search?email=${values}`);
}

export {
    addBasicDetails,
    getCountries,
    sendOtp,
    verifyOtp,
    checkNiyoUserEmail
};