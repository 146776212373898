import Header from '../header';
import Footer from '../footer';
import { LayoutProps } from './interface';
import { useLocation } from 'react-router-dom';

const Layout = (props: LayoutProps) => {
    const location = useLocation();

    const isActiveRoute = (route:string) => {
        return location.pathname === route;
      };
    return (
        <>
        { !isActiveRoute('/') && <Header /> }
           
            {props.children}
            <Footer />
        </>
    );
};

export default Layout;