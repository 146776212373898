import { API_URL } from '../../../config';
import { axios } from '../../../lib/axios';
import { RegionsResponse, CountriesResponse, BundlesResponse } from '../interface';

const getRegions = async (): Promise<RegionsResponse> => {
    return await axios.get(`${API_URL}/regions`);
}

const getCountries = async (params : any): Promise<CountriesResponse> => {
    return await axios.get(`${API_URL}/regions/countries`, { params: params });
}
const getPopularCountries = async (params : any): Promise<CountriesResponse> => {
    const headers = {
        'X-Origin-Domain' : "niyo.commbitz.com"
      };
    return await axios.get(`${API_URL}/niyo/countries`, {params : params, headers});
}

const getPopularCountriesBundls = async (params: any): Promise<CountriesResponse> => {
    const headers = {
        'X-Origin-Domain' : "niyo.commbitz.com"
      };
    return await axios.get(`${API_URL}/bundle/partnerWebsites`, {params: params, headers});
}

const getBundles = async (params: any) : Promise<BundlesResponse>=> {
    return await axios.get(`${API_URL}/bundle`, { params: params });
}

export {
    getRegions,
    getCountries,
    getBundles,
    getPopularCountries,
    getPopularCountriesBundls
};