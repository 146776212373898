import { API_URL } from "../config";
import { axios } from "./axios";

export const partnerLogin = async (values: any): Promise<any> => {

    const headers = {
      'X-Origin-Domain' : "niyo.commbitz.com"
    };
  
    // Pass the headers as the third parameter
    return axios.post(`${API_URL}/auth/partnerLogin`, values, { headers });
  };