import React from 'react'
import log from "../../assets/images/log.png"
import comLogoWhite from "../../assets/images/comLogoWhite.svg"
import { Link } from 'react-router-dom'
import { COMMBITZ_URL } from '../../config'

function Footer() {
    return (
        <footer className="footer-main">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="fottre-left text-center">
                            <div className='footerLogo'>
                                <Link to='/'><span> <img src={log} alt="" /></span></Link>
                                <p>Enjoy your international travel with the best debit and credit card Niyo Global and enjoy zero markups on your Forex transactions.</p>
                                <Link to={`${COMMBITZ_URL}`}><span><img src={comLogoWhite} alt="" /></span></Link>
                            </div>

                        </div>
                    </div>
                    {/* <div className="col-md-2">
                        <div className="fottre-link">
                            <h6>Quicklinks</h6>
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">e-Sims</a></li>
                                <li><a href="#">Blogs</a></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="col-md-2">
                        <div className="fottre-link">
                            <h6>Marketplace</h6>
                            <ul>
                                <li><a href="#">Trend</a></li>
                                <li><a href="#">Discount</a></li>
                                <li><a href="#">Featured</a></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="col-md-2">
                        <div className="fottre-link">
                            <h6>Company</h6>
                            <ul>
                                <li><a href="#">About</a></li>
                                <li><a href="#">Careers</a></li>
                                <li><a href="#">Partners</a></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="col-md-3">
                        <div className="fottre-form">
                            <h5>Subscribe</h5>
                            <p>Are you interested in follow to a particular website</p>
                            <div className="form-group">
                                <input type="text" placeholder="Your email address" />
                                <button type="submit"><i className="fas fa-location-arrow"></i></button>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </footer>
    )
}

export default Footer